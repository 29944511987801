import { settings } from './env_config';
import { getRequest, postRequest, putRequest } from './request_service';

function getInvestorApiUrl() {
  return sessionStorage['INVESTOR_API_URL'] || settings['INVESTOR_API_URL'];
}

function apiUrl(path) {
  return `${getInvestorApiUrl()}${path}`;
}

// Authenticated GET for investor-api
function getApiRequest(auth_token, path, headers = {}) {
  headers['Authorization'] = `Bearer ${auth_token}`;
  return getRequest(apiUrl(path), headers);
}

// Authenticated POST for investor-api
function postApiRequest(auth_token, path, data, headers = {}) {
  headers['Authorization'] = `Bearer ${auth_token}`;
  return postRequest(apiUrl(path), data, headers);
}

// Authenticated PUT for investor-api
function putApiRequest(auth_token, path, data, headers = {}) {
  headers['Authorization'] = `Bearer ${auth_token}`;
  return putRequest(apiUrl(path), data, headers);
}

function getBackendApiUrl(investor_api_backend) {
  switch (investor_api_backend) {
    case 'investor_backend':
      return settings['INVESTOR_API_URL'];
    case 'data_fondue_backend':
    default:
      return settings['DATA_FUND_API_URL'];
  }
}

export function setBackendApiUrl(investor_api_backend) {
  sessionStorage['INVESTOR_API_URL'] = getBackendApiUrl(investor_api_backend);
}

export function inlinePublicDocumentUrl(documentName) {
  return apiUrl(`/document/inline-public/${documentName}`);
}

export function inlineDocumentUrl(url) {
  return apiUrl(`/document/inline?url=${encodeURIComponent(url)}`);
}

export function confirmPersonInfoUpdate(uuid, token) {
  return postRequest(apiUrl(`/person/changes/${uuid}/confirm?token=${token}`), {});
}

export function verifyChangeToken(uuid, token) {
  return postRequest(apiUrl(`/person/changes/${uuid}/verify?token=${token}`), {});
}

export function getPerson(investor_token) {
  return getApiRequest(investor_token, '/person');
}

export function createPerson(investor_token, data) {
  return postApiRequest(investor_token, '/person', data);
}

export function updatePerson(investor_token, data) {
  return putApiRequest(investor_token, '/person', data);
}

export function getDocuments(investor_token) {
  return getApiRequest(investor_token, '/document');
}

export function getDocument(investor_token, type, id) {
  return getApiRequest(investor_token, `/document/${type}/${id}`);
}
