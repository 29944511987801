import { inlinePublicDocumentUrl } from '@helpers/investor_service';
import React, { Component } from 'react';
import styled from 'styled-components';

const DocumentViewer = styled.object`
  height: 800px;
  width: 100%;
`;

class InvestorDocumentViewer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      url: null,
      name: null,
    };
  }

  componentDidMount() {
    let url = this.props.url;
    if (this.props.public_document && !url) {
      url = inlinePublicDocumentUrl(this.props.public_document);
    }
    this.setState({
      url,
      name: this.props.name,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.url && prevState.url !== this.props.url) {
      this.setState({
        url: this.props.url,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.url && (
          <DocumentViewer data={this.state.url}>
            <p>
              Could not show this document in your web browser. Please{' '}
              <a href={this.state.url} target="_blank" rel="noreferrer noopener">
                download the {this.state.name}
              </a>{' '}
              instead.
            </p>
          </DocumentViewer>
        )}
      </>
    );
  }
}

export default InvestorDocumentViewer;
