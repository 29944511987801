const config = {
  development: {
    HOST: 'localhost',
    DATA_FUND_API_URL: 'https://localhost',
    INVESTOR_API_URL: 'https://localhost:5000',
    BALANCE_TRANSFER_TYPEFORM: 'https://delphia-application.typeform.com/to/HUGWL3kd',
    ACCOUNT_CANCEL_FEEDBACK_SURVEY: 'https://delphia-application.typeform.com/to/y9DIXnki',
    PLAID_ENV: 'sandbox',
    PLAID_PUBLIC_KEY: '2899136c4556115e6ca037fe2031dc',
    APPLE_SIGN_IN_CLIENT_ID: 'com.delphia.login-dev',
    BRANCH_KEY: 'key_test_lnSpoMpSZvDL3jxD4ESmyhnixvgXlbRj',
    GOOGLE_SIGN_IN_CLIENT_ID: '510416945066-c5sv94c71uuvt2p0bi1sg50rqc68c80n.apps.googleusercontent.com',
    CRYPTO_URL: 'http://localhost:3000',
  },
  devbox: {
    HOST: 'devbox',
    DATA_FUND_API_URL: 'https://devbox',
    INVESTOR_API_URL: 'https://devbox:5000',
    BALANCE_TRANSFER_TYPEFORM: 'https://delphia-application.typeform.com/to/HUGWL3kd',
    ACCOUNT_CANCEL_FEEDBACK_SURVEY: 'https://delphia-application.typeform.com/to/y9DIXnki',
    PLAID_ENV: 'sandbox',
    PLAID_PUBLIC_KEY: '2899136c4556115e6ca037fe2031dc',
    APPLE_SIGN_IN_CLIENT_ID: 'com.delphia.login-dev',
    BRANCH_KEY: 'key_test_lnSpoMpSZvDL3jxD4ESmyhnixvgXlbRj',
    GOOGLE_SIGN_IN_CLIENT_ID: '510416945066-c5sv94c71uuvt2p0bi1sg50rqc68c80n.apps.googleusercontent.com',
    CRYPTO_URL: 'https://web.staging.delphia.com',
  },
  staging: {
    HOST: 'website-staging.delphia.com',
    DATA_FUND_API_URL: 'https://data-fund-api-staging.delphia.com',
    INVESTOR_API_URL: 'https://investor-api-staging.delphia.com',
    BALANCE_TRANSFER_TYPEFORM: 'https://delphia-application.typeform.com/to/HUGWL3kd',
    ACCOUNT_CANCEL_FEEDBACK_SURVEY: 'https://delphia-application.typeform.com/to/y9DIXnki',
    PLAID_ENV: 'sandbox',
    PLAID_PUBLIC_KEY: '2899136c4556115e6ca037fe2031dc',
    APPLE_SIGN_IN_CLIENT_ID: 'com.delphia.login-staging',
    BRANCH_KEY: 'key_test_lnSpoMpSZvDL3jxD4ESmyhnixvgXlbRj',
    GOOGLE_SIGN_IN_CLIENT_ID: '589858171246-nasf335lk8t5c1cabi5ip1aju1ugrsni.apps.googleusercontent.com',
    CRYPTO_URL: 'https://web.staging.delphia.com',
  },
  production: {
    HOST: 'delphia.com',
    DATA_FUND_API_URL: 'https://data-fund-api.delphia.com',
    INVESTOR_API_URL: 'https://investor-api.delphia.com',
    BALANCE_TRANSFER_TYPEFORM: 'https://delphia-application.typeform.com/to/KGuzgLto',
    ACCOUNT_CANCEL_FEEDBACK_SURVEY: 'https://delphia-application.typeform.com/to/f4U8Btdf',
    PLAID_ENV: 'production',
    PLAID_PUBLIC_KEY: '2899136c4556115e6ca037fe2031dc',
    APPLE_SIGN_IN_CLIENT_ID: 'com.delphia.login',
    BRANCH_KEY: 'key_live_gbPlmNhRXuzT8huE6zFBPephqzf8koA1',
    GOOGLE_SIGN_IN_CLIENT_ID: '126238782792-d6i505p53gmbi0ljr8fo4j9fg68iospo.apps.googleusercontent.com',
    CRYPTO_URL: 'https://web.delphia.com',
  },
};

function getSettings() {
  let settings;
  // When building the site statically, there is no window.
  if (typeof window !== 'undefined') {
    settings = Object.values(config).find(c => c.HOST === window.location.hostname);
  }
  // For an unknown host, or during static compilation, return an undefined config.
  // We should never be relying on settings then.
  return settings;
}

export const settings = getSettings();
