import { DocumentViewer } from '@components/investor_documents';
import Layout, { Body, BodyWrapper } from '@components/layout';
import * as React from 'react';
import styled from 'styled-components';

const StyledBodyWrapper = styled(BodyWrapper)`
  grid-template-rows: [body-start] auto [body-end];
`;

class FeesAndChargesPage extends React.PureComponent<PageProps, PageState> {
  render() {
    return (
      <Layout pageName="Fees and Charges" location={this.props.location}>
        <StyledBodyWrapper>
          <Body>
            <DocumentViewer public_document="fees_and_charges" name="Fees and Charges" />
          </Body>
        </StyledBodyWrapper>
      </Layout>
    );
  }
}

export default FeesAndChargesPage;
